import React, { useState } from 'react';
import { HoverCard } from './HoverCard';

export function ImageModal({ imageUrl, altText, onClose, likeCount, id }) {
  const [isHovered, setIsHovered] = useState(false);
  console.log("likeCountm", likeCount)
  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <div 
        style={{
          maxWidth: '90%',
          maxHeight: '90%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img 
          src={imageUrl} 
          alt={altText} 
          style={{
            maxWidth: '100%',
            maxHeight: '90vh',
            objectFit: 'contain',
          }}
        />
        {isHovered && (
          <div style={{
            position: 'absolute',
            bottom: '10px',
            // top: '50%',
            left: '50%',
            transform: 'translate(-50%)',
            zIndex: 1001,
          }}>
            <HoverCard likeCount={likeCount} id={id} />
          </div>
        )}
        <button 
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          ×
        </button>
      </div>
    </div>
  );
}