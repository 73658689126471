import React, { useEffect, useState, useCallback } from "react";
import { useWindowDimensions } from './useWindowDimensions';
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Card } from './Card';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";

export function Gallery() {
    const [images, setImages] = useState([]);
    const { width } = useWindowDimensions();
    const gridTemplateColumns = width < 1000 ? "auto auto" : "auto auto auto auto auto";

    const fetchImages = useCallback(async () => {
      initializeApp(firebaseConfig)
        const db = getFirestore();
        const imagesCol = collection(db, 'images');
        const imageSnapshot = await getDocs(imagesCol);
        
        const imageList = imageSnapshot.docs.map(doc => ({ data: doc.data(), id: doc.id }));
        setImages(imageList);
        console.log(imageList)
    }, []);

    useEffect(() => {
        fetchImages();
    }, [fetchImages]);

    const randomizeOrder = () => {
        setImages(prevImages => [...prevImages].sort(() => Math.random() - 0.5));
    };
    console.log(images)
    return (
        <div>
            
            <div  
                style={{ 
                    display: "grid", 
                    gridTemplateColumns,
                }}>
                {images.map(info => 
                    <Card 
                        info={info} 
                        key={info.id} 
                        id={info.id} 
                    />
                )}
            </div>
        </div>
    );
}