import React from 'react';
import "react-toggle/style.css" // for ES6 modules
import { Home } from './Home';
import { UserUploads } from './UserUploads';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {

   

    
   

    return (
        <>
        
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/userupload" element={<UserUploads/>}/>
            </Routes>
        </Router>
        </>
    );
}



export default App;