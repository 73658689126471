import { useState, memo } from "react"
// import { HoverCard } from "./HoverCard"
import { ImageModal } from "./ImageModal"

export const Card = memo(function Card({ info, id }) {
    const [isHovered, setIsHovered] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { compressedUrl, originalUrl, likeCount, altText } = info.data
    const handleClick = () => {
        setIsModalOpen(true);
    }

    return (
        <>
            <div 
                style={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto',
                    overflow: 'hidden',
                    padding: '10px',
                    borderRadius: '8px',
                    cursor: 'zoom-in'
                }}
                id={likeCount}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick}
            >
                                <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                <img
                    loading="lazy"
                    src={compressedUrl}
                    alt={altText || "Image in gallery"}
                    style={{
                        // maxWidth: '400px',
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        opacity: isHovered ? 0.7 : 1,
                        borderRadius: 5,
                        transition: 'opacity 0.3s ease-in-out'
                    }}
                />
                
            </div>
            </div>
            {isModalOpen && (
                <ImageModal 
                    imageUrl={originalUrl}
                    altText={altText}
                    onClose={() => setIsModalOpen(false)}
                    id={id}
                    likeCount={likeCount}

                />
            )}
        </>
    )
})