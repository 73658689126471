
export const firebaseConfig = {
  apiKey: "AIzaSyAyDkxnLmH5u0TVDyETT6HeX0zK7faW8oI",
  authDomain: "database-example-3360a.firebaseapp.com",
  projectId: "database-example-3360a",
  storageBucket: "database-example-3360a.appspot.com",
  messagingSenderId: "4351367029",
  appId: "1:4351367029:web:2e44b2e3d2f6a1b440a53c",
  measurementId: "G-W0JRYTK41Y"
};

