import { FaFacebook } from 'react-icons/fa';
import { TiArrowUpOutline, TiArrowUpThick } from "react-icons/ti";
import {useState} from "react"
import { getFirestore, doc, updateDoc, increment } from 'firebase/firestore';

export function HoverCard({name, url, likeCount, id}){
    const [isLiked, setIsLiked] = useState(false)
    // const link = "https://database-example-3360a.web.app"+"#"+prop.name
    const link = url
    const db = getFirestore();

    async function incrementLikeCountById(docID) {
        const userRef = doc(db, 'images', docID); // Create a reference to the specific document
        console.log(userRef, "userref")

        try {
            await updateDoc(userRef, {
                likeCount: increment(1) // Increment likeCount by 1
            });
            setIsLiked(true)
        } catch (error) {
            console.error('Error incrementing likeCount:', error);
        }
    }

    return <>
    <div className="hovercontainer" style={{
            cursor: 'pointer',  
            display: "flex",
            backgroundColor: 'black',
            color: 'white',
            padding: '15px 20px',
            borderRadius: '10px',
            textAlign: 'center',
            maxWidth: '200px',
            width: 'auto',
            boxSizing: 'border-box',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(5px)',
            }}>
        <div className='sharecontainer'>
            <div id="fb-root"></div>
            <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0" nonce="hPWem1ED"></script>
            <div className="fb-share-button" data-href={link} data-layout="" data-size=""><a target="_blank" href={"https://www.facebook.com/sharer/sharer.php?u="+link} class="fb-xfbml-parse-ignore" rel="noreferrer"><FaFacebook size={28}/></a></div>
        </div>
        &nbsp; &nbsp; 
            <div style={{display: 'flex', alignItems: 'center'}}>
                {!isLiked ? <TiArrowUpOutline onClick={()=>{incrementLikeCountById(id)}} size={28}/>: <TiArrowUpThick size={28}/>}
            </div>
            &nbsp; &nbsp; 
        <div style={{fontSize: 26, color: 'white'}}>{likeCount}</div>


    </div>
    </>
}