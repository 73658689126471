import { PhotoUpload } from './PhotoUpload';
import { Gallery } from './Gallery';
import Toggle from 'react-toggle'
import useLocalStorage from "use-local-storage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { FaMoon, FaRegSun } from "react-icons/fa";
import { IoIosRefresh } from "react-icons/io";
import { IoPerson } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider } from "firebase/auth";   


export function Home() {
        const [showUpload, setShowUpload] = useState(false);
        const [refresh, doRefresh] = useState(0);
        const [darkMode, setDarkMode] = useLocalStorage("darkModePhotoApp", false);
        const [user, setUser] = useState(null);
        useEffect(() => {
            initializeApp(firebaseConfig)
            const auth = getAuth();
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                console.log("Auth state changed:", user ? "User logged in" : "No user");
                setUser(user);
            });
    
            return () => unsubscribe();
        }, []);

        const headerStyle = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            backgroundColor: darkMode ? 'black' : 'white',
        };
        const uploadButtonStyle = {
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
        };
        const handleUploadClick = async () => {
            if (user) {
                setShowUpload(true);
            } else {
                const auth = getAuth();
                const provider = new GoogleAuthProvider();
                try {
                    await signInWithPopup(auth, provider);
                } catch (error) {
                    console.error("Error during Google sign-in:", error);
                }
            }
        };
    return <div className="App" style={{backgroundColor: darkMode ? 'black' : 'white'}}>
<header style={headerStyle}>
  <div className='Header' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    {<div><a href='https://apple.co/4epxcuk'>Download App</a>&nbsp;&nbsp;</div>}
    <button onClick={handleUploadClick} style={uploadButtonStyle}>
        {user ? 'Upload Photo' : 'Sign In to Upload'}
    </button>
    &nbsp;&nbsp;
    <IoIosRefresh size={28} color={darkMode?'white':'black'} onClick={() => doRefresh(prev => prev + 1)} />
    &nbsp;&nbsp;

    
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
 
    <NavLink to="/userupload">
        <IoPerson size={28} color={darkMode?'white':'black'}/>
    </NavLink>
    </div>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
    <label style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
      <Toggle
       
        // icons={{
        //   checked: <FaMoon size={28}/>,
        //   unchecked: <FaRegSun color='yellow'/>,
        // }}
        onChange={()=>{setDarkMode(!darkMode)}}
         />
         Dark Mode
    </label>
    </div>
    </div>
</header>
{showUpload && user && <PhotoUpload onClose={() => setShowUpload(false)} />}

<Gallery refresh={refresh}/>

</div>
}
