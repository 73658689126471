import React, { useEffect, useState } from "react";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Card } from './Card';
import { useWindowDimensions } from './useWindowDimensions';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { NavLink } from "react-router-dom";
import { FaHome } from "react-icons/fa";


export function UserUploads() {
    const [images, setImages] = useState([]);
    const { width } = useWindowDimensions();
    const gridTemplateColumns = width < 1000 ? "auto" : "auto auto";
    initializeApp(firebaseConfig)

    const auth = getAuth();

    useEffect(() => {
        const fetchUserImages = async () => {
            if (!auth.currentUser) return;
            const db = getFirestore();
            const imagesRef = collection(db, "images");
            const q = query(imagesRef, where("uid", "==", auth.currentUser.uid));
            const querySnapshot = await getDocs(q);
            
            const userImages = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
            setImages(userImages);
        };

        fetchUserImages();
    }, [auth.currentUser]);

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px'}}>
            <h2 >My Uploads</h2>
            &nbsp;&nbsp;
            <NavLink to="/"><FaHome size={28}/></NavLink>
            </div>
            <div  
                style={{ 
                    display: "grid", 
                    gridTemplateColumns,
                    gap: "20px"
                }}>
                {images.map(image => (
                    <Card key={image.id} info={image} id={image.id} />
                ))}
            </div>
        </div>
    );
}