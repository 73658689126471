import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export function PhotoUpload({ onClose }) {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = async () => {
        if (!file) return;
        setUploading(true);

        try {
            const app = initializeApp(firebaseConfig);

            const storage = getStorage();
            const auth = getAuth();
            const db = getFirestore();
            const functions = getFunctions(app, 'us-central1');
            // const functions = getFunctions();
            // Upload original image
            const originalStorageRef = ref(storage, `images/${file.name}`);
            const uploadTask = uploadBytesResumable(originalStorageRef, file);

            await new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                    },
                    (error) => {
                        console.error("Upload failed:", error);
                        reject(error);
                    },
                    () => {
                        resolve();
                    }
                );
            });

            const originalDownloadURL = await getDownloadURL(originalStorageRef);

            // Call Cloud Function to compress image and generate alt text
            const processImage = httpsCallable(functions, 'processImage');
            const result = await processImage({ 
                imageUrl: originalDownloadURL, 
                fileName: file.name 
            });

            const { compressedImageUrl, altText } = result.data;

            // Save image data to Firestore
            await addDoc(collection(db, "images"), {
                originalUrl: originalDownloadURL,
                compressedUrl: compressedImageUrl,
                uid: auth.currentUser.uid,
                likeCount: 0,
                fileName: file.name,
                altText: altText,
            });

            console.log("Upload successful!");
            setUploading(false);
            onClose();
        } catch (error) {
            console.error("Error during upload:", error);
            setUploading(false);
        }
    };

    return (
        <div style={uploadContainerStyle}>
            <h2 style={headerStyle}>Upload a Photo</h2>
            <button onClick={onClose} style={closeButtonStyle}>×</button>
            <input type="file" onChange={handleFileChange} accept="image/*" style={fileInputStyle} />
            <div style={buttonContainerStyle}>
                <button onClick={handleUpload} disabled={!file || uploading} style={uploadButtonStyle}>
                    {uploading ? 'Uploading...' : 'Upload Photo'}
                </button>
            </div>
        </div>
    );
}

const uploadContainerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    width: '300px',
    maxWidth: '90%',
};

const headerStyle = {
    marginTop: '0',
    marginBottom: '20px',
};

const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '24px',
    cursor: 'pointer',
    padding: '0',
    lineHeight: '1',
};

const fileInputStyle = {
    display: 'block',
    width: '100%',
    marginBottom: '20px',
};

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
};

const uploadButtonStyle = {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
};